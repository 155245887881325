<template>
  <div class="intro">
    <div class="intro__top-logo">
      <img src="@img/dolphin.svg" alt="" class="intro__top-logo__img" />
    </div>
    <div class="container">
      <div class="intro__center-items">
        <router-link :to="{ name: 'Home' }" class="intro-item">
          <p>معرفی دلفین</p>
        </router-link>
        <router-link :to="{ name: 'ProductsList' }" class="intro-item">
          <p>محصولات</p>
        </router-link>
        <router-link :to="{ name: 'SaleAgents' }" class="intro-item">
          <p>عاملین فروش</p>
        </router-link>
        <router-link :to="{ name: 'ImagesAndVideos' }" class="intro-item">
          <p>تصاویر و فیلم ها</p>
        </router-link>
        <router-link :to="{ name: 'ProductBenefits' }" class="intro-item">
          <p>مزایا و ویژگی های محصولات</p>
        </router-link>
        <router-link :to="{ name: 'ContactUs' }" class="intro-item">
          <p>تماس با ما</p>
        </router-link>
        <router-link :to="{ name: 'Languages' }" class="intro-item">
          <p>انتخاب زبان</p>
        </router-link>
      </div>
    </div>
    <div class="intro__bottom">
      <p>All The Rights Reserved</p>
      <div class="intro__bottom-rights">
        <p>By Sinoos <span>&copy;</span> 2021</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Intro",
  methods: {
    navigateToHome(language) {
      switch (language) {
        case "Home":
          this.$router.push({ name: "Home" })
          break
        case "Products":
          this.$router.push({ name: "ProductsList" })
          break
        case "Contact-us":
          this.$router.push({ name: "ContactUs" })
          break
        case "Languages":
          this.$router.push({ name: "Languages" })
          break
        default:
          break
      }
    }
  }
}
</script>

<style scoped lang="scss">
.intro {
  padding-top: 20px;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background: var(--primary-color) url("../assets/images/Moj.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}
.intro__top-logo {
  align-self: center;
  margin-bottom: 30px;
}
.intro__top-logo__img {
  width: 200px;
  height: 40px;
}
.intro-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 60px;
  border-radius: 10px;
  margin-bottom: 5px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}
.intro-item:last-child {
  margin-bottom: 0;
}
.intro-item__logo {
  width: 60px;
  height: 40px;
  border-radius: 5px;
}
.intro__bottom {
  align-self: center;
  margin-top: auto;
  color: #fff;
  flex-direction: column;
  display: flex;
  padding-bottom: 10px;
}
.intro__bottom-rights {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.intro__bottom-rights span {
  margin-left: 10px;
}
.intro__bottom-rights p {
  font-family: "Times New Roman", Times, serif;
  margin-bottom: 3px;
}
</style>