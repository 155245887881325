<!-- eslint-disable -->
<template>
  <router-view />
</template>

<script>
/* eslint-disable */
export default {
  name: "Home"
}
</script>

<style lang="scss">
@font-face {
  font-family: "iransans";
  src: url("../src/assets/fonts/IRANSansWeb.ttf");
}
:root {
  --bgc-color: #ddd;
  --bgc-color2: #bbb;
  --gray-color: #999;
  --primary-color: #2c00a3;
  --secondary-color: #ffa500;
  --dark: #111;
  --light: #fff;
}
* {
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}
body {
  direction: rtl;
  font-family: "iransans";
  scroll-behavior: smooth;
}
a {
  color: #fff;
}
.container {
  padding: 0px 80px;
  width: 100%;
}
.main-section {
  margin: 80px 0 65px;
  color: #111;
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 1000px) {
  .container {
    padding: 0 40px;
  }
}
@media only screen and (max-width: 678px) {
  .container {
    padding: 0 20px;
  }
}
</style>
