<template>
  <div class="footer">
    <div class="footer-items">
      <router-link :to="{ name: 'Intro' }" class="footer__home footer-item">
        <svg viewBox="0 1 511 511.999" class="icon">
          <path
            d="m498.699219 222.695312c-.015625-.011718-.027344-.027343-.039063-.039062l-208.855468-208.847656c-8.902344-8.90625-20.738282-13.808594-33.328126-13.808594-12.589843 0-24.425781 4.902344-33.332031 13.808594l-208.746093 208.742187c-.070313.070313-.144532.144531-.210938.214844-18.28125 18.386719-18.25 48.21875.089844 66.558594 8.378906 8.382812 19.441406 13.234375 31.273437 13.746093.484375.046876.96875.070313 1.457031.070313h8.320313v153.695313c0 30.417968 24.75 55.164062 55.167969 55.164062h81.710937c8.285157 0 15-6.71875 15-15v-120.5c0-13.878906 11.292969-25.167969 25.171875-25.167969h48.195313c13.878906 0 25.167969 11.289063 25.167969 25.167969v120.5c0 8.28125 6.714843 15 15 15h81.710937c30.421875 0 55.167969-24.746094 55.167969-55.164062v-153.695313h7.71875c12.585937 0 24.421875-4.902344 33.332031-13.8125 18.359375-18.367187 18.367187-48.253906.027344-66.632813zm-21.242188 45.421876c-3.238281 3.238281-7.542969 5.023437-12.117187 5.023437h-22.71875c-8.285156 0-15 6.714844-15 15v168.695313c0 13.875-11.289063 25.164062-25.167969 25.164062h-66.710937v-105.5c0-30.417969-24.746094-55.167969-55.167969-55.167969h-48.195313c-30.421875 0-55.171875 24.75-55.171875 55.167969v105.5h-66.710937c-13.875 0-25.167969-11.289062-25.167969-25.164062v-168.695313c0-8.285156-6.714844-15-15-15h-22.328125c-.234375-.015625-.464844-.027344-.703125-.03125-4.46875-.078125-8.660156-1.851563-11.800781-4.996094-6.679688-6.679687-6.679688-17.550781 0-24.234375.003906 0 .003906-.003906.007812-.007812l.011719-.011719 208.847656-208.839844c3.234375-3.238281 7.535157-5.019531 12.113281-5.019531 4.574219 0 8.875 1.78125 12.113282 5.019531l208.800781 208.796875c.03125.03125.066406.0625.097656.09375 6.644531 6.691406 6.632813 17.539063-.03125 24.207032zm0 0"
          />
        </svg>
      </router-link>
      <span class="divider"></span>
      <router-link :to="{ name: 'Home' }" class="footer-item">
        <svg class="icon" viewBox="0 0 512.009 512.009">
          <path
            d="m492.231 137.786-15.183-7.588c-5.679-14.547-22.367-45.934-63.982-59.81-28.565-9.519-102.702-34.225-179.515-9.5-35.303-28.258-62.108-29.876-115.53-23.196-12.198 1.526-16.948 7.903-18.785 12.983-8.399 23.227 31.165 33.144 36.888 72.651-24.777 20.282-54.479 51.189-70.427 83.086-37.981 75.962-27.415 141.982-20.445 168.582-24.636 18.878-39.781 47.176-45.083 84.312-1.825 12.783 11.489 22.327 22.995 16.572l72.845-36.422 56.845 28.422c9.764 4.885 21.469-1.253 22.998-12.071 5.625-39.788-2.997-71.888-41.367-88.87-8.081-3.581-17.53.074-21.106 8.155-3.577 8.08.075 17.53 8.155 21.106 14.85 6.573 22.24 16.034 23.693 32.09l-42.063-21.031c-4.504-2.252-9.807-2.252-14.311 0l-48.084 24.042c6.987-15.679 17.417-27.58 31.47-36.011 6.692-4.015 9.532-12.218 6.694-19.486-.068-.178-6.8-18.077-8.202-46.1-1.866-37.277 6.07-73.943 23.588-108.979 15.037-30.074 46.104-60.517 68.138-77.447 4-3.074 6.317-7.854 6.25-12.898-.378-28.647-13.934-49.362-25.541-63.138 41.904-3.232 53.54 4.429 76.882 23.968 4.407 3.688 10.455 4.729 15.842 2.729 69.323-25.76 137.812-2.936 167.056 6.811 36.193 12.067 44.96 42.545 45.524 44.646 1.065 4.662 4.103 8.347 8.384 10.487l21.068 10.529c3.533 1.765 2.303 7.158-1.695 7.158-21.291 0-79.302-2.468-160.543 21.085-8.466-9.074-23.647-5.432-27.062 6.52-8.191 28.669-23.726 46.944-47.134 55.224 10.303-21.892 14.519-45.089 14.519-58.828 0-8.837-7.164-16-16-16s-16 7.163-16 16c0 .425-.008.877-.023 1.349-43.267 5.917-96.497 38.163-124.917 77.241-7.757 10.666.055 25.411 12.927 25.411 4.945 0 9.822-2.285 12.953-6.591 22.388-30.783 61.185-53.917 91.702-61.952-4.314 11.826-10.758 24.03-19.955 33.229-10.046 10.046-2.929 27.313 11.313 27.313 48.883 0 85.557-21.627 104.502-64.508 72.764-23.122 134.872-23.491 135.498-23.492h28.22c9.562 0 18.548-3.721 25.304-10.477 17.165-17.165 12.666-46.324-9.3-57.306z"
          />
        </svg>
      </router-link>
      <span class="divider"></span>
      <router-link
        :to="{ name: 'ProductsList' }"
        class="footer__products footer-item"
      >
        <svg viewBox="0 0 450.391 450.391" class="icon">
          <path
            d="M143.673,350.322c-25.969,0-47.02,21.052-47.02,47.02c0,25.969,21.052,47.02,47.02,47.02
				c25.969,0,47.02-21.052,47.02-47.02C190.694,371.374,169.642,350.322,143.673,350.322z M143.673,423.465
				c-14.427,0-26.122-11.695-26.122-26.122c0-14.427,11.695-26.122,26.122-26.122c14.427,0,26.122,11.695,26.122,26.122
				C169.796,411.77,158.1,423.465,143.673,423.465z"
          />
          <path
            d="M342.204,350.322c-25.969,0-47.02,21.052-47.02,47.02c0,25.969,21.052,47.02,47.02,47.02s47.02-21.052,47.02-47.02
				C389.224,371.374,368.173,350.322,342.204,350.322z M342.204,423.465c-14.427,0-26.122-11.695-26.122-26.122
				c0-14.427,11.695-26.122,26.122-26.122s26.122,11.695,26.122,26.122C368.327,411.77,356.631,423.465,342.204,423.465z"
          />
          <path
            d="M448.261,76.037c-2.176-2.377-5.153-3.865-8.359-4.18L99.788,67.155L90.384,38.42
				C83.759,19.211,65.771,6.243,45.453,6.028H10.449C4.678,6.028,0,10.706,0,16.477s4.678,10.449,10.449,10.449h35.004
				c11.361,0.251,21.365,7.546,25.078,18.286l66.351,200.098l-5.224,12.016c-5.827,15.026-4.077,31.938,4.702,45.453
				c8.695,13.274,23.323,21.466,39.184,21.943h203.233c5.771,0,10.449-4.678,10.449-10.449c0-5.771-4.678-10.449-10.449-10.449
				H175.543c-8.957-0.224-17.202-4.936-21.943-12.539c-4.688-7.51-5.651-16.762-2.612-25.078l4.18-9.404l219.951-22.988
				c24.16-2.661,44.034-20.233,49.633-43.886l25.078-105.012C450.96,81.893,450.36,78.492,448.261,76.037z M404.376,185.228
				c-3.392,15.226-16.319,26.457-31.869,27.69l-217.339,22.465L106.58,88.053l320.261,4.702L404.376,185.228z"
          />
        </svg>
      </router-link>
      <i class="divider"></i>
      <router-link
        :to="{ name: 'ContactUs' }"
        class="footer__about-us footer-item"
      >
        <svg class="icon" viewBox="0 -60 511.99782 511">
          <path
            d="m505.917969 5.453125c-.203125-.207031-.417969-.402344-.640625-.582031-3.253906-2.722656-7.4375-4.371094-12.003906-4.371094h-242.886719c-4.144531 0-7.5 3.359375-7.5 7.5s3.355469 7.5 7.5 7.5h230.226562l-136.402343 95.019531c-3.265626 2.269531-7.648438 2.269531-10.90625 0l-136.40625-95.019531h18.488281c4.140625 0 7.5-3.359375 7.5-7.5 0-4.144531-3.359375-7.5-7.5-7.5h-31.148438c-4.570312 0-8.761719 1.648438-12.015625 4.382812-.21875.175782-.425781.363282-.625.566407-3.730468 3.425781-6.085937 8.324219-6.085937 13.777343v187.691407c0 5.5 2.398437 10.4375 6.183593 13.863281.152344.152344.308594.300781.476563.433594 3.261719 2.757812 7.46875 4.429687 12.066406 4.429687h309.035157c4.589843 0 8.796874-1.667969 12.058593-4.421875.171875-.140625.335938-.296875.496094-.453125 3.78125-3.425781 6.167969-8.359375 6.167969-13.851562v-187.691407c.003906-5.449218-2.351563-10.347656-6.078125-13.773437zm-153.136719 117.375.886719-.617187 54.820312 38.191406c3.382813 2.355468 8.0625 1.546875 10.4375-1.867188 2.371094-3.398437 1.535157-8.074218-1.867187-10.441406l-50.269532-35.023438 130.210938-90.707031v181.417969l-51.222656-35.6875c-3.394532-2.371094-8.070313-1.535156-10.4375 1.863281-2.367188 3.398438-1.535156 8.074219 1.863281 10.441407l43.410156 30.246093h-283.714843l126.949218-88.429687.882813.613281c8.390625 5.84375 19.660156 5.84375 28.050781 0zm-172.269531-100.464844 130.214843 90.710938-130.214843 90.707031zm0 0"
          />
          <path
            d="m216.859375 388.386719c17.75 3.613281 35.824219 3.71875 53.703125.289062 4.066406-.78125 6.730469-4.710937 5.953125-8.78125-.78125-4.066406-4.710937-6.726562-8.78125-5.953125-15.921875 3.058594-32.035156 2.972656-47.886719-.253906-102.144531-20.773438-181.570312-100.203125-202.347656-202.347656-8.285156-40.726563 4.3125-82.582032 33.699219-111.96875 10.144531-10.140625 26.644531-10.140625 36.785156 0l42.15625 42.160156c7.917969 7.914062 7 21.011719-1.925781 27.75l-26.210938 19.792969c-15.621094 11.800781-20.363281 33.039062-10.527344 50.070312 24.070313 41.722657 58.84375 76.5 100.5625 100.5625 17.023438 9.839844 38.269532 5.109375 50.074219-10.523437l19.792969-26.210938c6.726562-8.902344 19.8125-9.863281 27.753906-1.925781l42.160156 42.160156c10.140626 10.140625 10.140626 26.640625 0 36.78125-8.984374 8.984375-19.257812 16.5-30.542968 22.339844-3.675782 1.90625-5.113282 6.433594-3.210938 10.109375 1.90625 3.679688 6.429688 5.117188 10.109375 3.210938 12.652344-6.550782 24.175781-14.980469 34.25-25.054688 15.988281-15.988281 15.988281-42.003906 0-57.992188l-42.160156-42.160156c-14.355469-14.355468-38.105469-12.691406-50.328125 3.492188l-19.792969 26.210937c-7.050781 9.339844-20.246093 12.570313-30.601562 6.574219-39.445313-22.75-72.320313-55.625-95.074219-95.070312-5.992188-10.394532-2.75-23.558594 6.578125-30.601563l26.207031-19.792969c16.207032-12.238281 17.835938-35.988281 3.496094-50.328125l-42.160156-42.160156c-15.988282-15.988281-42.003906-15.988281-57.992188 0-32.957031 32.953125-47.085937 79.894531-37.796875 125.5625 21.980469 108.058594 106.003907 192.082031 214.058594 214.058594zm0 0"
          />
        </svg>
      </router-link>
    </div>
  </div>
  <div class="apple"></div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      urlLink: this.$route.fullPath
    }
  },
  methods: {
    handleActivedLink() {
      // footer items => svgs
      const footerIcons = document.querySelectorAll(".icon")
      // this method will remove active class from each footer svgs
      const removeActiveClass = () => {
        footerIcons.forEach((icon) => icon.classList.remove("active"))
      }
      // this variable will cut the / from url
      // and return 4 remaining words from start of the url link
      const e = this.urlLink.slice(1, 5)
      switch (e) {
        case "home":
          footerIcons[1].classList.add("active")
          break
        case "prod":
          removeActiveClass()
          footerIcons[2].classList.add("active")
          break
        case "cont":
          removeActiveClass()
          footerIcons[3].classList.add("active")
          break
        default:
          break
      }
    }
  },
  mounted() {
    this.handleActivedLink()
  }
}
</script>

<style scoped lang="scss">
.footer {
  position: fixed;
  bottom: 15px;
  right: 0;
  left: 0;
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.apple {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 15px;
  background-color: var(--bgc-color);
}
.icon {
  fill: var(--gray-color);
  width: 35px;
  height: 35px;
  transition: all 0.4s ease;
}
.icon.active {
  fill: var(--primary-color);
}
.footer-item:hover .icon {
  fill: var(--primary-color);
}
.footer-items {
  display: grid;
  grid-template-columns: 1fr 1px 1fr 1px 1fr 1px 1fr;
  height: 100%;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.4s ease;
  background: var(--bgc-color);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.footer-item {
  background-color: var(--bgc-color);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  fill: #fff;
}
.divider {
  height: 60%;
  background-color: var(--bgc-color2);
}
.footer__items:last-child {
  border: none;
}
</style>