<template>
  <Navbar />
  <section v-if="!isLoading" class="products main-section">
    <div class="container">
      <p class="products__title">لیست محصولات</p>
      <div class="products__wrapper">
        <router-link
          :to="{ name: 'Product', params: { id: product.pk } }"
          v-for="product in products"
          :key="product.pk"
          class="products-item"
        >
          <div class="products__header">
            <img
              :src="product.image_url"
              :alt="product.pk"
              class="products__header-img"
            />
          </div>
          <div class="products__footer">
            <p class="products__footer-text">{{ product.name }}</p>
          </div>
        </router-link>
      </div>
    </div>
  </section>
  <section v-else>
    <Loading />
  </section>
  <Footer />
</template>

<script>
import Navbar from "@common/Navbar.vue"
import { getProductList } from "@services/CategoryService"
import Loading from "@common/Loading.vue"
export default {
  components: { Navbar, Loading },
  name: "products-list",
  data() {
    return {
      products: [],
      isLoading: false
    }
  },
  methods: {
    async fetchProductsList() {
      try {
        const res = await getProductList()
        const data = res.data
        this.products = data
        this.isLoading = !this.isLoading
      } catch (err) {
        console.error(err)
      }
    }
  },
  created() {
    this.isLoading = !this.isLoading
    this.fetchProductsList()
  }
}
</script>

<style scoped lang="scss">
.products__title {
  text-align: center;
}
.products {
  padding-top: 10px;
}
.products__wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;
}
.products-item {
  padding: 10px;
}
.products__header {
  margin: auto;
  overflow: hidden;
  border-radius: 10px;
}
.products__header-img {
  width: 100%;
  height: 280px;
  border-radius: 10px;
  object-fit: cover;
  object-position: center;
  transition: all 0.3s ease;
}
.products__footer {
  font-size: 15px;
  color: #333;
  text-align: center;
}
.products__footer-text {
  color: var(--dark);
}
@media only screen and (max-width: 820px) {
  .products__wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 500px) {
  .products__wrapper {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
}
</style>
