<template>
  <Navbar />
  <section v-if="!isLoading" class="home main-section">
    <div class="container">
      <div v-html="pageData.desc"></div>
    </div>
  </section>
  <section v-else>
    <Loading />
  </section>
  <Footer />
</template>

<script>
import { getPageData } from "@services/HomeService"
import Loading from "@common/Loading.vue"
export default {
  name: "Home",
  data() {
    return {
      pageData: {},
      isLoading: false
    }
  },
  components: { Loading },
  methods: {
    async getPageData() {
      try {
        const res = await getPageData(1)
        this.pageData = res.data
        this.isLoading = !this.isLoading
      } catch (error) {
        console.error(error)
      }
    }
  },
  created() {
    this.isLoading = !this.isLoading
    this.getPageData()
  }
}
</script>

<style scoped lang="scss">
.home {
  padding: 10px 0 20px;
}
</style>
