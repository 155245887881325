<!-- eslint-disable -->
<template>
  <Navbar />
  <div class="main-section" v-html="pageData.desc">
  </div>
  <Footer />
</template>

<script>
/* eslint-disable */
import {getPageData} from '../services/HomeService'
import Navbar from '@common/Navbar.vue'
import Footer from '@common/BaseFooter.vue'
export default {
  name : "SaleAgents",
  data(){
    return {
      pageData : {}
    }
  },
  components:{
    Navbar,
    Footer
  },
  methods:{
    async fetchPageData(){
      const res = await getPageData(3)
      const data = res.data
      this.pageData = data
    }
  },
  created(){
    this.fetchPageData()
  }
}
</script>

<style>

</style>