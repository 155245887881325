<template>
  <section class="loading">
    <img src="@img/loading.svg" alt="" />
  </section>
</template>

<style scoped>
.loading {
  background: #2c00a3;
  z-index: 50;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
}
</style>