/* eslint-disable */
import { createRouter, createWebHistory } from "vue-router"

import ContactUs from "@view/ContactUs.vue"
import Home from "@view/Home.vue"
import NotFound from "@view/404.vue"
import ProductsList from "@view/ProductsList.vue"
import Product from "@view/Product.vue"
import Languages from "@view/Languages.vue"
import Intro from "@view/Intro.vue"
import SaleAgents from "@view/SaleAgents.vue"
import ImagesAndVideos from "@view/ImagesAndVideos.vue"
import ProductBenefits from "@view/ProductBenefits.vue"

const routes = [{
        path: "/",
        name: "Languages",
        component: Languages
    },
    {
        path: "/intro",
        name: "Intro",
        component: Intro
    },
    {
        path: "/home",
        name: "Home",
        component: Home
    },
    {
        path: "/product",
        name: "ProductsList",
        component: ProductsList,
        props: true
    },
    {
        path: "/product/:id",
        name: "Product",
        component: Product,
        props: true,
        linkActiveClass: true
    },
    {
        path: "/sale-agents",
        name: "SaleAgents",
        component: SaleAgents
    },
    {
        path: "/images-and-videos",
        name: "ImagesAndVideos",
        component: ImagesAndVideos
    },
    {
        path: "/advantage-and-benefits-of-products",
        name: "ProductBenefits",
        component: ProductBenefits
    },
    {
        path: "/contact-us",
        name: "ContactUs",
        component: ContactUs
    },
    {
        path: "/:cathAll(.*)",
        name: "NotFound",
        component: NotFound
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkActiveClass: "active",
    scrollBehavior() {
        return { top: 0 }
    }
})

export default router