import App from "./App.vue"
import "./registerServiceWorker"
import Navbar from "./components/common/Navbar"
import footer from "./components/common/BaseFooter"
import { createApp } from "vue"
import router from "./router"

const app = createApp(App)
app.use(router)
app.component("Navbar", Navbar)
app.component("Footer", footer)
app.mount("#app")