<template>
  <div class="not-found">
    <img src="../assets/images/404/404.png" class="not-found__img" alt="" />
    <p class="not-found__code">404</p>
    <p class="not-found__title">صفحه مورد نظر یافت نشد</p>
    <router-link :to="{ name: 'Home' }" class="not-found__link"
      >بازگشت</router-link
    >
  </div>
</template>

<style scoped>
.not-found {
  width: 100vw;
  height: 100vh;
  background: var(--primary-color);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.not-found__code {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  color: var(--light);
  font-size: 80px;
  margin-bottom: 30px;
}
.not-found__title {
  color: var(--light);
  font-size: 30px;
  font-weight: lighter;
  margin-bottom: 20px;
}
.not-found__link {
  background-color: #3b00dd;
  padding: 10px 20px;
  color: var(--light);
  border-radius: 10px;
}
.not-found__img {
  width: 300px;
  height: 300px;
}
</style>
