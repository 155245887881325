import axios from "axios"

const apiRequest = axios.create({
  baseURL: "https://panel.dolphinsalt.com/api/",
  headers: {
    "Content-Type": "application/json"
  },
  timeout: 0
})

apiRequest.interceptors.request.use(
  (config) => {
    // console.log(
    //   `${config.method} request Sent To this Url => "${
    //     config.url
    //   }" at ${new Date()}`
    // );
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

apiRequest.interceptors.response.use(null, (error) => {
  if (
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500
  ) {
    return Promise.reject()
  }
})

export default {
  get: apiRequest.get,
  post: apiRequest.post,
  put: apiRequest.put,
  delete: apiRequest.delete
}
