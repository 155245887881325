import axios from "axios"

const apiClient = axios.create({
    baseURL: "https://www.dolphinsalt.com/api/v1/",
    headers: {
        "Content-Type": "application/json"
    },
    timeout: 0
})

apiClient.interceptors.request.use(
    (config) => {
        // console.log(
        //   `${config.method} request Sent To this Url => "${
        //     config.url
        //   }" at ${new Date()}`
        // );
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

apiClient.interceptors.response.use(null, (error) => {
    if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500
    ) {
        return Promise.reject()
    }
})

export default {
    get: apiClient.get,
    post: apiClient.post,
    put: apiClient.put,
    delete: apiClient.delete
}