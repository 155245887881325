<template>
  <div class="navbar">
    <div class="container">
      <div class="navbar-wrapper">
        <div class="navbar__nav">
          <ul class="navbar-menu">
            <li class="nav-items">
              <router-link :to="{ name: 'Intro' }" class="nav-link"
                >خانه</router-link
              >
            </li>
            <li class="nav-items">
              <router-link :to="{ name: 'Home' }" class="nav-link"
                >معرفی دلفین</router-link
              >
            </li>
            <li class="nav-items">
              <router-link :to="{ name: 'ProductsList' }" class="nav-link"
                >محصولات</router-link
              >
            </li>
            <li class="nav-items">
              <router-link :to="{ name: 'ContactUs' }" class="nav-link"
                >تماس با ما</router-link
              >
            </li>
          </ul>
        </div>
        <div class="navbar__logo">
          <router-link :to="{ name: 'Languages' }">
            <div class="navbar__logo-flag">
              <img src="@img/down-chevron.svg" class="down-chevron" />
              <img
                class="navbar__logo-flag__icon"
                src="@img/flag-icons/Iran.png"
                alt=""
              />
            </div>
          </router-link>
          <router-link :to="{ name: 'Home' }" class="nav-link">
            <img src="@img/logo-nav.svg" alt="" class="logo" />
          </router-link>
          <router-link :to="{ name: 'Intro' }" class="nav__backward">
            <img src="@img/left-chevron-svgrepo-com.svg" alt="" />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      urlLink: this.$route.fullPath
    }
  },
  methods: {
    handleActivedLink() {
      // header items => links
      const links = document.querySelectorAll(".nav-link")
      // footer items => svgs
      const footerIcons = document.querySelectorAll(".icon")
      // this method will remove active class from each footer svgs
      const removeActiveClass = () => {
        footerIcons.forEach((icon) => icon.classList.remove("active"))
      }
      // this variable will cut the / from url
      // and return 4 remaining words from start of the url link
      const e = this.urlLink.slice(1, 5)
      switch (e) {
        case "home":
          links[1].classList.add(
            "router-link-active",
            "router-link-exact-active"
          )
          removeActiveClass()
          footerIcons[1].classList.add("active")
          break
        case "prod":
          links[2].classList.add(
            "router-link-active",
            "router-link-exact-active"
          )
          removeActiveClass()
          footerIcons[2].classList.add("active")
          break
        case "cont":
          links[3].classList.add(
            "router-link-active",
            "router-link-exact-active"
          )
          removeActiveClass()
          footerIcons[3].classList.add("active")
          break
        default:
          break
      }
    }
  },
  mounted() {
    this.handleActivedLink()
  }
}
</script>

<style scoped lang="scss">
.navbar {
  width: 100%;
  background: var(--bgc-color);
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}
.navbar-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}
.navbar-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  .nav-items {
    cursor: pointer;
    .nav-link {
      height: 100%;
      width: 100%;
      padding: 29px 18px 27px;
      margin: 0 10px;
      position: relative;
      font-size: 15px;
      color: #999;
      &::after {
        content: "";
        width: 0%;
        height: 3px;
        background-color: #2c00a3;
        position: absolute;
        bottom: 0;
        right: 0;
        transition: all 0.3s ease-in-out;
      }
      &:hover::after {
        width: 100%;
      }
    }
    .router-link-active::after {
      width: 100%;
    }
  }
}
.down-chevron {
  color: #777;
  width: 8px;
  margin-left: 5px;
  transition: all 0.4s ease;
}
.navbar__logo {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.navbar__logo-flag {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 30px;
  padding: 5px;
  background: #ccc;
  border-radius: 5px;
  transition: all 0.4s ease;
  cursor: pointer;
  &:hover .fa-icon {
    color: #555;
  }
}
.navbar__logo-flag__icon {
  width: 25px;
}
.navbar__logo .logo {
  width: 200px;
  height: 40px;
  object-fit: scale-down;
  vertical-align: middle;
}

.nav-link__icon {
  width: 200px;
  height: auto;
}
.nav__backward img {
  width: 30px;
  height: 30px;
}
.nav__backward {
  display: none;
}
@media only screen and (max-width: 800px) {
  .navbar-wrapper {
    justify-content: flex-end;
  }
  .navbar__nav {
    display: none;
  }
  .nav__backward {
    display: flex;
  }
  .modal {
    width: 50%;
  }
  .navbar__logo {
    width: 100%;
    justify-content: space-between;
  }
}
@media only screen and (max-width: 500px) {
  .modal {
    width: 80%;
  }
}
</style>