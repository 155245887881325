<template>
  <Navbar />
  <section v-if="!isLoading" class="main-section">
    <div class="container">
      <div class="product-wrapper">
        <div class="product" v-for="(prod, idx) in product" :key="idx">
          <div class="product__header">
            <img
              :src="prod.image_url"
              :alt="prod.pk"
              class="product__header-image"
            />
          </div>
          <div class="product__footer">
            <h1>{{ prod.name }}</h1>
            <h4>{{ prod.desc }}</h4>
          </div>
        </div>
        <!-- daste bandi ha -->
        <h3 class="category-title">بسته بندی ها</h3>

        <div class="product__category">
          <div
            v-for="(product, idx) in productItems"
            :key="idx"
            class="product__category-item"
          >
            <div class="product__category-item__header">
              <img
                :src="product.image_url"
                :alt="product.pk"
                class="product__category-item-img"
              />
            </div>
            <div class="product__category-item__footer">
              <p>{{ product.name }}</p>
              <span class="product__category-item__footer-price">{{
                product.price
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section v-else>
    <Loading />
  </section>
  <Footer />
</template>

<script>
import { getProduct } from "@services/CategoryService"
import Navbar from "@common/Navbar.vue"
import Loading from "@common/Loading.vue"
export default {
  components: { Navbar, Loading },
  data() {
    return {
      product: [],
      productItems: [],
      isLoading: false
    }
  },
  props: {
    id: {
      type: String
    }
  },
  methods: {
    async fetchGetProduct() {
      try {
        const res = await getProduct(this.id)
        const productData = res.data.product
        this.product.push(productData)
        res.data.items.forEach((item) => {
          this.productItems.push(item)
        })
        this.isLoading = !this.isLoading
      } catch (err) {
        console.error(err)
      }
    }
  },
  created() {
    this.isLoading = !this.isLoading
    this.fetchGetProduct()
  }
}
</script>

<style scoped lang="scss">
.product-wrapper {
  padding: 10px 0;
}
.product {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.product__header {
  width: 100%;
  align-self: center;
  .product__header-image {
    height: auto;
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
  }
}
.product__footer {
  padding: 5px 10px;
  text-align: center;
  color: var(--dark);
  h1 {
    font-weight: normal;
  }
  h4 {
    font-weight: normal;
  }
}
.category-title {
  margin-bottom: 10px;
  font-weight: normal;
}

// product Items
.product__category {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  width: 70%;
}

.product__category-item-img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}
.product__category-item__footer-price {
  color: var(secondary-color);
}
.product__category-item__footer {
  text-align: center;
}

// responsive design
@media only screen and (max-width: 800px) {
  .product__category {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    width: auto;
  }
}
@media only screen and (max-width: 680px) {
  .product {
    margin: 30px 0;
    border-radius: 5px;
    display: grid;
    grid-template-columns: 1fr;
    .product__footer {
      h1 {
        text-align: center;
        font-size: 22px;
      }
      h4 {
        text-align: start;
        margin-top: 10px;
        font-size: 17px;
      }
    }
    .product__header-image {
      width: 100%;
      height: 100%;
      border-radius: 5px 5px 0 0;
    }
  }
  .product__category {
    width: auto;
  }
}
@media only screen and (max-width: 500px) {
  .product__category {
    display: grid;
    width: auto;
  }
}
</style>