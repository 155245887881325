<template>
  <Navbar />
  <section v-if="!isLoading" class="main-section">
    <div class="container">
      <div v-html="pageData.desc" class="contact-us"></div>
    </div>
  </section>
  <section v-else>
    <Loading />
  </section>
  <Footer />
</template>

<script>
import { getPageData } from "@services/HomeService"
import Loading from "@common/Loading.vue"
export default {
  name: "About-us",
  components: { Loading },
  data() {
    return {
      pageData: {},
      isLoading: false
    }
  },
  methods: {
    async getPageData() {
      try {
        const res = await getPageData(2)
        this.pageData = res.data
        this.isLoading = !this.isLoading
      } catch (err) {
        console.error(err)
      }
    }
  },
  created() {
    this.isLoading = !this.isLoading
    this.getPageData()
  }
}
</script>

<style lang="scss" scoped>
.contact-us {
  padding: 10px 0;
  height: 100vh;
}
</style>