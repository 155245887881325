import http from "./HttpCategories"


export const getProductList = () => {
  return http.get(`/product/`)
}

export const getProduct = (id) => {
  return http.get(`/product/${id}/`)
}
